import { Button } from "client/src/components/Button/Button";
import { SlobDropdown } from "client/src/components/SlobDropdown/SlobDropdown";
import { StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";

import { useToggler } from "../../../hooks/useToggler";
import { Checkbox } from "../Checkbox";

import * as styles from "./menuWithCheckboxes.module.less";
import type { CheckboxChangeEvent } from "../Checkbox";

// https://www.w3.org/TR/wai-aria-practices-1.1/examples/checkbox/checkbox-2/checkbox-2.html
// https://www.w3.org/TR/wai-aria-practices-1.1/examples/menubar/menubar-2/menubar-2.html

type Props = {
  variant?: "primary" | "secondary";
  name: string;
  label: string;
  disabled?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  menuItemCheckboxes: Array<{
    id: string;
    label: string;
    name: string;
    value?: string;
    checked: boolean | "mixed";
    onChange: (e: CheckboxChangeEvent) => void;
  }>;
};

export function MenuWithCheckboxes({
  variant = "primary",
  name,
  label,
  disabled,
  getPopupContainer,
  menuItemCheckboxes,
}: Props) {
  const [visible, toggleVisible] = useToggler();

  const labelId = `${name}__label`;
  const descriptionId = `${name}__description`;

  const checkedItems = menuItemCheckboxes.filter((i) => i.checked !== false);
  const description =
    checkedItems.length > 0
      ? checkedItems.length > 1
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
          `${checkedItems[0]!.label} +${checkedItems.length - 1}`
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
          checkedItems[0]!.label
      : null;

  return (
    <SlobDropdown
      trigger={["click"]}
      open={visible}
      getPopupContainer={getPopupContainer}
      placement="bottom"
      dropdownRender={() => (
        <div className={clsx("p-24", styles.overlay)} role="menu" aria-labelledby={labelId}>
          <StackY dist={8}>
            {menuItemCheckboxes.map((m) => (
              <Checkbox
                key={m.id}
                id={m.id}
                role="menuitemcheckbox"
                label={m.label}
                name={m.name}
                value={m.value}
                checked={m.checked === "mixed" ? false : m.checked}
                onChange={m.onChange}
                disabled={disabled}
                indeterminate={m.checked === "mixed"}
              />
            ))}
          </StackY>
        </div>
      )}
    >
      <>
        {variant === "primary" && (
          <div className={styles.menuWithCheckboxes}>
            <div className={styles.labelContainer}>
              <label id={labelId} className={clsx(styles.label, description && "sr-only")}>
                {label}
              </label>

              <div
                id={descriptionId}
                className={clsx(styles.description, disabled && styles.disabled)}
              >
                {description}
              </div>
            </div>

            <button
              className={clsx(
                styles.menuWithCheckboxesSelect,
                visible && styles.menuWithCheckboxesSelect__open,
                disabled && styles.disabled,
              )}
              type="button"
              onClick={toggleVisible}
              disabled={disabled}
              aria-haspopup="menu"
              aria-labelledby={labelId}
              aria-describedby={descriptionId}
              aria-expanded={visible}
            />
          </div>
        )}

        {variant === "secondary" && (
          <Button
            id={labelId}
            type="text-only"
            onClick={toggleVisible}
            aria-haspopup="menu"
            aria-expanded={visible}
            disabled={disabled}
          >
            {label}
          </Button>
        )}
      </>
    </SlobDropdown>
  );
}
